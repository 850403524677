<template>
    <div class="page-show-item change_responsive show-user">
        <div class="main-content">
            <div class="header-with-tabs row-space-tbf">
                <div class="space-left"></div>
                <div class="content">
                    <div v-if="$resize && ($mq.above(1025) && !$mq.between([1025, 1730]))" class="tabs">
						<div class="title-tab" :class="{active: activeTab == 'contract_data'}" @click="activeTab = 'contract_data', changeTab(activeTab)">{{ $t('salary.show-contract.tab_contract_data') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'coinsured'}" @click="activeTab = 'coinsured', changeTab(activeTab)">{{ $t('salary.show-contract.tab_coinsured') }}</div>
                        <div class="title-tab" :class="{active: activeTab == 'sick-leaves'}" @click="activeTab = 'sick-leaves', changeTab(activeTab)">{{ $t('salary.show-contract.tab_sick-leaves') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'vacations'}" @click="activeTab = 'vacations', changeTab(activeTab)">{{ $t('salary.show-contract.tab_vacations') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'benefits'}" @click="activeTab = 'benefits', changeTab(activeTab)">{{ $t('salary.show-contract.tab_benefits') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'history'}" @click="activeTab = 'history', changeTab(activeTab)">{{ $t('salary.show-contract.tab_history') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'suspension'}" @click="activeTab = 'suspension', changeTab(activeTab)">{{ $t('salary.show-contract.tab_suspension') }}</div>
						<div class="title-tab" :class="{active: activeTab == 'detachment'}" @click="activeTab = 'detachment', changeTab(activeTab)">{{ $t('salary.show-contract.tab_detachment') }}</div>
					</div>
                    <div v-if="$resize && ($mq.below(1024) || $mq.between([1025, 1730]))" class="actions-dropdown">
						<div class="filter-dropdown dropdown first">
							<button class="btn-tbf white drop" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<span class="text">{{ $t(`salary.show-contract.tab_${activeTab}`) }}</span>
								<div class="icon-filter"><icon-arrow /></div>
							</button>
							<div class="dropdown-menu" aria-labelledby="dropdownFilter">
								<div class="dropdown-item" :class="{active: activeTab == 'contract_data'}" @click="activeTab = 'contract_data', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('salary.show-contract.tab_contract_data') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'coinsured'}" @click="activeTab = 'coinsured', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('salary.show-contract.tab_coinsured') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'sick-leaves'}" @click="activeTab = 'sick-leaves', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('salary.show-contract.tab_sick-leaves') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'vacations'}" @click="activeTab = 'vacations', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('salary.show-contract.tab_vacations') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'benefits'}" @click="activeTab = 'benefits', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('salary.show-contract.tab_benefits') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'history'}" @click="activeTab = 'history', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('salary.show-contract.tab_history') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'suspension'}" @click="activeTab = 'suspension', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('salary.show-contract.tab_suspension') }}</span>
									</div>
								</div>
								<div class="dropdown-item" :class="{active: activeTab == 'detachment'}" @click="activeTab = 'detachment', changeTab(activeTab)">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t('salary.show-contract.tab_detachment') }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
                    <div class="actions">
						<button class="btn-tbf blue show" :class="{mobile: $resize && $mq.below(1254)}" @click="contract_end_date ? $root.$emit('open_modal', 'error', {data: $t('salary.show-contract.error_add_modification', {'msg': contract_end_date})}, false) : modificationContract(activeTab)" v-if="['contract_data'].includes(activeTab)">
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(1255)" class="text">{{ $t('general.add_modification') }}</span>
						</button>
                        <button class="btn-tbf blue show" :class="[{mobile: $resize && $mq.below(1254)}, {hide: ['history', 'sick-leaves', 'vacations', 'benefits', 'suspension', 'detachment'].includes(activeTab) }]" @click="goCreateEntry(activeTab)" v-else>
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(1255)" class="text">{{ $t('general.add') }}</span>
						</button>
						<div class="download-button dropdown">
							<div class="icon settings" id="dropdownDownload" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<icon-download />
							</div>
							<div class="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownDownload">
								<div @click="showModal('statements',  {type: 'statements', from: 'index_contracts-statements', model: contract})" class="dropdown-item">
									<div class="text">{{ $t('statements.title') }}</div>
								</div>
								<div class="dropdown-item" @click="showModal('situation', {type: 'income_situation', from: 'index_contracts_income_situation', text: $t('salary.download.income_situation_title'), model: contract})"><div class="text">{{$t('salary.download.income_situation')}}</div></div>
								<div class="dropdown-item" @click="showModal('situation', {type: 'co_situation', from: 'index_contracts_co_situation', text: $t('salary.download.co_situation_title'), model: contract})"><div class="text">{{$t('salary.download.co_situation')}}</div></div>
								<div class="dropdown-item" @click="showModal('situation', {type: 'cm_situation', from: 'index_contracts_cm_situation', text: $t('salary.download.cm_situation_title'), model: contract})"><div class="text">{{$t('salary.download.cm_situation')}}</div></div>
							</div>
                    	</div>
					</div>
                </div>
                <div class="space-right"></div>
            </div>

            <div class="tab-content">
				<tab-contract-data :data="contract" v-if="activeTab == 'contract_data'" />
				<tab-sick-leaves v-if="activeTab == 'sick-leaves'" />
                <tab-coinsured v-if="activeTab == 'coinsured'" />
				<tab-vacation v-if="activeTab == 'vacations'" />
				<tab-benefits v-if="activeTab == 'benefits'" />
				<tab-history v-if="activeTab == 'history'" />
				<tab-suspension v-if="activeTab == 'suspension'" />
				<tab-detachment v-if="activeTab == 'detachment'" />
			</div>
            
        </div>
        <div class="sidebar-user">
            <div class="space-left-custom"></div>
            <div class="content">
                <div class="actions-header">
					<button @click="$router.push({name: 'contract-edit', params: {id: contract.id}})">
						<div class="text ">{{ $t('general.edit_contract') }}</div>
					</button>

					<button class="delete-btn" @click="showModal('delete',  {type: 'contract', from: 'show_contracts', model: contract})">
						<div class="text ">{{ $t('general.delete') }}</div>
					</button>
				</div>

                <div class="header-user">
					<div class="data">
						<div class="name">
							<!-- <div class="circle-status" :class="{active: contract.status === 'active'}"></div> -->
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="circle-status" :class="{active: contract.status === 'active'}"></div>
									<template slot="popover">
										<div class="simple-text">{{ $t('salary.contract-data.active_contract') }}</div>
									</template>
								</v-popover>
							{{ `${contract.first_name} ${contract.last_name ? contract.last_name : ''}` }}</div>
						<div class="tag">{{ contract.email }}</div>
					</div>

					<div class="image">
						<img :src="contract.avatar" v-if="contract.avatar">
						<div class="user-circle" v-else>
							<icon-user />
						</div>
					</div>
				</div>
				<div class="box-label-text" v-if="contract.roles && contract.roles.length > 0">
					<div class="label">{{ $t('show_user.roles') }}</div>
					<div class="text">{{ contract.roles.map((el) => { return el['name']}).join(", ") }}</div>
				</div>
				<div class="box-label-text" v-if="contract.departments && contract.departments.length > 0">
					<div class="label">{{ $t('profile.departments') }}</div>
					<div class="text">{{ contract.departments.map((el) => { return el['name']}).join(", ") }}</div>
				</div>
				<div class="box-label-text" v-if="contract.workstation_name">
					<div class="label">{{ $t('sidebar-org.workstation') }}</div>
					<div class="text">{{ contract.workstation_name}}</div>
				</div>
				<div class="box-table details">
					<div class="header-title">
						<div class="title">
							{{ $t('salary.contract-data.contract_details') }}
						</div>
					</div>
					<div class="box-radius">
						<div class="inline-data">
                    		<div class="value">{{ contract.contract_type ? contract.contract_type.name : '' }}</div>
						</div>
						<div class="inline-data">
							<div class="label">{{ $t('salary.contract-data.number-contract') }}</div>
							<div class="value">{{ contract.contract_number }}</div>
						</div>
						<div class="inline-data">
							<div class="label">{{ $t('salary.contract-data.mark') }}</div>
							<div class="value">{{ contract.marca }}</div>
						</div>
						<div class="inline-data">
							<div class="label">{{ $t('salary.contract-data.contract-date') }}</div>
							<div class="value">{{ contract.contract_date | moment('DD MMM YYYY') }}</div>
						</div>
						<div class="inline-data">
							<div class="label">{{ $t('salary.contract-data.first_day_of_activity') }}</div>
							<div class="value">{{ contract.first_day_of_activity | moment('DD MMM YYYY') }}</div>
						</div>
						<div v-if="contract.contract_details" class="inline-data">
							<div class="label">{{ $t('salary.contract-data.bank') }}</div>
							<div v-if="contract.contract_details.bank" class="value">{{ contract.contract_details.bank.name | moment('DD MMM YYYY') }}</div>
						</div>
						<div v-if="contract.contract_details" class="inline-data">
							<div class="label">{{ $t('salary.contract-data.iban') }}</div>
							<div class="value">{{ contract.contract_details.iban_code | moment('DD MMM YYYY') }}</div>
						</div>
					</div>
				</div>
				
				 <div class="accordion-sidebar">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseSalary">
						<div class="icon"><icon-user /></div>

						<div class="title">{{ $t('salary.contract-data.employer_data') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseSalary" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseSalary">
						<div class="box-radius">
							<div v-if="contract.cnp" class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.cnp') }}</div>
								<div class="value">{{ contract.cnp}}</div>
							</div>
                            <div v-if="contract.citizenship" class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.citizenship') }}</div>
								<div class="value">{{ contract.citizenship}}</div>
							</div>
                            <div v-if="contract.street_and_number" class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.address') }}</div>
								<div class="value">{{ contract.street_and_number }}</div>
							</div>
							 <div v-if="contract.series && contract.number" class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.identity_data') }}</div>
								<div class="value">{{ contract.series + ' ' +  contract.number}}</div>
							</div>
						</div>
					</div>
				</div>
				
                <div class="accordion-sidebar">
					<div class="header-accordion" v-bind:class="{collapsed: !contract.last_day_of_activity}" data-toggle="collapse" data-target="#collapseLiquidation">
						<div class="icon"><icon-liquidation /></div>
						<div class="title">{{ $t('salary.show-contract.liquidation') }}</div>
						<div class="actions">
							<button class="btn-tbf blue center add-btn" @click.stop="showModal('liquidation', {update: contract.last_day_of_activity ? true : false, id: contract.id, type: 'liquidation'})">
								<div class="text">{{ contract.last_day_of_activity ? $t('general.update') : $t('general.complete') }}</div>
							</button>
							<button class="arrow-collapse show" v-bind:class="{collapsed: !contract.last_day_of_activity}" type="button" data-toggle="collapse" data-target="#collapseLiquidation" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" v-bind:class="{show: contract.last_day_of_activity}" id="collapseLiquidation">
						<div class="box-radius">
							<div v-if="contract.resignation_date" class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.leave_date') }}</div>
								<div class="value">{{ contract.resignation_date | moment('DD MMM YYYY')}}</div>
							</div>
							<div v-if="contract.contract_end_date" class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.contract_end_date') }}</div>
								<div class="value">{{ contract.contract_end_date | moment('DD MMM YYYY')}}</div>
							</div>
                            <div class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.last_day_of_activity') }}</div>
								<div class="value" v-bind:class="{'last_day_of_activity': contract.last_day_of_activity}">{{ contract.last_day_of_activity | moment('DD MMM YYYY')}}</div>
							</div>
							 <div class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.liquidation_reason') }}</div>
								<div class="value">{{ contract.liquidation_reason ? contract.liquidation_reason.name : ''}}</div>
							</div>
                            <div v-if="contract.liquidation_observations" class="inline-data">
                                <div class="label">{{ $t('salary.contract-data.contract_observations') }}</div>
								<div class="value">{{ contract.liquidation_observations }}</div>
							</div>
						</div>
					</div>
				</div>

				<div class="accordion-sidebar" v-if="contract.contract_observations">
					<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseContractObservations">
						<div class="icon"><icon-user /></div>

						<div class="title">{{ $t('salary.contract-data.contract_observations') }}</div>
						<div class="actions">
							<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseContractObservations" aria-expanded="true">
								<icon-arrow />
							</button>
						</div>
					</div>

					<div class="collapse content-accordion" id="collapseContractObservations">
						<div class="box-radius">
							<div class="inline-data">
								<div class="value">{{ contract.contract_observations }}</div>
							</div>
						</div>
					</div>
				</div>
            </div>
        <div class="space-right-custom"></div>
    </div>
    </div>
</template>

<script>
	import IconDownload from '../../../Icons/Download'
    import IconArrow from '../../../Icons/Arrow'
    import IconUser from '../../../Icons/User'
    import IconPlus from '../../../Icons/Plus'
	import IconLiquidation from '../../../Icons/Liquidation'
    import TabContractData from './IndexContractData'
	import TabSickLeaves from './IndexSickLeaves'
    import TabCoinsured from './IndexCoinsured'
	import TabVacation from './IndexVacation'
	import TabBenefits from './IndexBenefits'
	import TabHistory from './IndexHistory'
	import TabSuspension from './IndexSuspension'
	import TabDetachment from './IndexDetachment'

    export default {
        data() {
			return {
				loaded: false,
				loadedList: false,
                contract: {},
                activeTab: this.$route.query.tab ? this.$route.query.tab : 'contract_data',
				contract_end_date: ''
			}
		},

        components: {
			IconDownload,
            IconArrow,
			IconUser,
            IconPlus,
			IconLiquidation,
            TabContractData,
			TabSickLeaves,
            TabCoinsured,
			TabVacation,
			TabBenefits,
			TabHistory,
			TabSuspension,
			TabDetachment
        },

        async mounted(){
			await this.getContractData()

			setTimeout(() => {
				var title = this.contract.first_name + ' ' + this.contract.last_name;
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('updateShowPage', () => {
            	this.getContractData()
			})
		},
		beforeDestroy() {
            this.$root.$off('updateShowPage')
        },
        methods: {
            async getContractData() {
				await axios.get(`/contracts/${this.$route.params.id}`, { params: {details: true}})
				.then(({data}) => {
                    this.contract = data.data
					this.contract_end_date = data.data.contract_end_date ? moment(data.data.contract_end_date).format('DD MMM YYYY') : ''
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})		
			},
            changeTab(item) {
				this.$router.push({name: 'contract-show', query: {tab: item}})
			},
			goCreateEntry(item){
				this.$router.push({name: `${item}-create`, params: {contract_id: this.contract.id}})
			},
			modificationContract() {
				this.$router.push({name: 'contract-modification-create', params: {contract_id: this.contract.id}})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
        }
    }
</script>

<style lang="scss">
@import "../../../../styles/pages/user_show.scss";
.btn-tbf{
	&.show {
		height: 36px !important;
		width: 167px;
	}
	&.drop {
		min-width: 100px !important;
	}
}
.actions {
	.download-button {
		margin-left: 15px;
	}
}

.inline-data{
	.last_day_of_activity{
		padding: 5px 10px;
		background: rgba(185, 14, 14, 0.767);
		color:white !important;
		font-size:14px;
		border-radius: 6px;
		font-weight: 500;
	}
}

.data{
	.name {
		display: flex;
		.circle-status{
			height: 10px;
			width: 10px;
			min-width: 10px;
			border-radius: 50%;
			background: #c5c5c5;
			border: 1px solid #747683;
			margin-right: 7px;
			margin-bottom: 3px;
			&.active {
				background: #6ce0a6;
				border: 1px solid #03ae58;
			}
		} 
	}
}

.box-table{
	&.details{
		margin-bottom: 30px;
	}
}
</style>